.contact-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 6rem;
}

.contact-section-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    text-decoration: underline;
}

.email-container {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.email-container p {
    font-weight: 600;
    font-size: 1.5rem;
    color: rgb(92, 92, 92);
}

.email-container img {
    width: 3rem;
}

.send-message-title {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.send-message-title h1 {
    font-weight: 600;
    font-size: 1.7rem;
}

.send-message-title img {
    width: 3rem;
}

.contact-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: rgba(222, 222, 222, 0.389);
    padding: 3rem;
    border-radius: 10px;
}

.contact-container input {
    width: 40rem;
    padding: 1rem;
    font-size: 1.1rem;
    border-radius: 10px;
    border: 1px solid black;
}

.contact-container textarea {
    width: 40rem;
    padding: 1rem;
    font-size: 1.1rem;
    height: 10rem;
    border-radius: 10px;
    border: 1px solid black;
}

.contact-send-btn {
    margin-top: 1rem;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
    cursor: pointer;
    font-weight: 600;
}

@media (max-width: 900px) {
    .email-container p {
        font-size: 1rem;
    }

    .email-container img {
        width: 2rem;
    }

    .send-message-title h1 {
        font-size: 1.2rem;
    }

    .send-message-title img {
        width: 2rem;
    }

    .contact-container {
        padding: 1.5rem;
    }

    .contact-container input {
        width: 35rem;
        font-size: 1rem;
        padding: 0.8rem;
    }

    .contact-container textarea {
        width: 35rem;
        font-size: 1rem;
        padding: 0.8rem;
    }

}

@media (max-width: 780px) {
    .contact-container input {
        width: 30rem;
    }

    .contact-container textarea {
        width: 30rem;
    }
}

@media (max-width: 650px) {
    .contact-container input {
        width: 25rem;
    }

    .contact-container textarea {
        width: 25rem;
    }
}

@media (max-width: 500px) {
    .contact-section {
        padding-top: 4rem;
    }

    .contact-container input {
        width: 20rem;
        padding: 0.5rem 0.8rem;
        font-size: 0.8rem;
    }

    .contact-container textarea {
        width: 20rem;
        padding: 0.5rem 0.8rem;
        font-size: 0.8rem;
    }
}

@media (max-width: 450px) {
    .contact-container input {
        width: 15rem;
        padding: 0.5rem 0.8rem;
        font-size: 0.8rem;
    }

    .contact-container textarea {
        width: 15rem;
        padding: 0.5rem 0.8rem;
        font-size: 0.8rem;
    }

    .send-message-title h1 {
        font-size: 1rem;
    }

    .send-message-title img {
        width: 1.5rem;
    }
}