.projects-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 6rem;
}

.projects-section-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    text-decoration: underline;
}

.projects-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 2rem;
}

.project-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 30rem;
    padding: 3rem;
    border-radius: 10px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

}

.project-container:hover {
    outline: 1px solid gray;
}

.project-demo-img {
    width: 100%;
    border: 1px solid gray;
}

.project-title {
    font-weight: 600;
    font-size: 1.2rem;
    color: rgb(46, 46, 46);
}

.project-description {
    color: rgb(78, 78, 78)
}

.project-links {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}

.project-app-link-img {
    width: 7rem;
}

.project-website-link {
    display: flex;
    align-items: center;
    gap: 0.7rem;
    padding: 0.4rem 3rem;
    text-decoration: none;
    color: black;
    border: 1px solid gray;
    border-radius: 7px;
}

.project-website-link-img {
   width: 1.3rem;
}

.project-website-text {
   font-size: 0.9rem;
   text-decoration: none; 
   color: black;
}

@media (max-width: 600px) {
    .project-website-link {
        padding: 0.4rem 1rem;
    }
    
    .project-website-text {
       font-size: 0.8rem;
    }

    .project-links {
       gap: 0.3rem;
    }

     .project-description {
        font-size: 0.8rem;
    }
}

@media (max-width: 500px) {
    .projects-section {
        padding-top: 4rem;
    }

    .project-container {
        width: 95%;
    }
}
