.about-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 0;
    height: 100vh;
}

.about-section-intro {
    display: flex;
    align-items: center;
    gap: 5rem;
}

.about-section-intro h1 {
    color: rgb(55, 55, 55);
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.about-section-intro p {
    font-size: 1.1rem;
    margin-top: 1rem;
    color: rgb(67, 67, 67);
}

.pin-emoji {
    width: 1.1rem;
}

.headshot-img {
    height: 18rem;
    width: 18rem;
    border-radius: 50%;
    border: 2px solid black;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.social-links {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
}

.social-links img {
    width: 2rem;
    transition: 0.2s ease-in-out;
}

.social-links img:hover {
    filter: invert(50%);
}

.about-section-view-work-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 5rem;
    padding: 1rem 6rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    background: none;
    border: 1px solid black;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.about-section-view-work-btn {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.about-section button:hover {
    transform: scale(1.1);
}

@media (max-width: 1150px) {
    .about-section-intro h1 {
        font-size: 2rem;
    }

    .about-section-intro p {
        font-size: 0.9rem;
    }

    .headshot-img {
        height: 14rem;
        width: 14rem;
    }
}

@media (max-width: 950px) {
    .about-section-intro {
        flex-direction: column;
        gap: 1rem;
    }

    .about-section-view-work-btn {
        margin-top: 2rem;
        padding: 0.9rem 3rem;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 0.9rem;
    }

    .about-section-intro-text {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-section-intro-text p {
        text-align: center;
    }
}

@media (max-width: 650px) {
    .about-section-intro h1 {
        font-size: 2rem;
    }

    .about-section-intro p {
        font-size: 0.8rem;
    }

    .pin-emoji {
        width: 0.9rem;
    }
}

@media (max-width: 500px) {
    .about-section-intro h1 {
        font-size: 1.5rem;
    }

    .about-section-intro p {
        font-size: 0.8rem;
        text-align: start;
    }
}

@media (max-width: 380px) {
    .headshot-img {
        width: 10rem;
        height: 10rem;
    }
}

@media (max-width: 370px) {
    .about-section-intro h1 {
        font-size: 1rem;
    }

    .about-section-intro p {
        font-size: 0.7rem;
    }
}