@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Courier+Prime&family=Poppins:wght@400;600&family=Roboto:wght@100;300&family=Teko:wght@500&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

body {
    background: white;
}

html {
    scroll-behavior: smooth;
}

.window {
    width: 100vw;
}

.all-sections-container {
    padding: 0 5rem;
    padding-bottom: 5rem;
    width: 100%;
    max-width: 1650px;
}

@media (max-width: 650px) {
    .all-sections-container {
        padding: 0 2rem;
        padding-bottom: 5rem;
    }
}